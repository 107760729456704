<template>
  <div class="padding24 color_white">
    <div v-if="isShow" >
      <div class="header-box">
        <router-link v-if="operation.add" to="/appletsManage/sharePoster/add/0">
          <a-button icon="plus" type="primary">新建海报</a-button>
        </router-link>
        <div class="table-operator">
          <span>状态：</span>
          <a-select ref="select" v-model="filter.state" placeholder="请选择" style="width: 120px">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="2">已上架</a-select-option>
            <a-select-option :value="3">已下架</a-select-option>
          </a-select>
          <a-input v-model="filter.name" style="width:150px;margin:0 20px" placeholder="请输入海报名称" />
          <a-button type="primary" @click="onSearch">搜索</a-button>
        </div>
      </div>
      <a-table
        :columns="sharePosterColumns"
        :data-source="tabledData"
        :pagination="false"
        :loading="tableLoading"
        :row-key="record => record.id"
      >
        <span slot="number" slot-scope="number, record, index">
          <span>{{ (pageNo - 1) * pageSize + index + 1 }}</span>
        </span>
        <img v-viewer class="avatar" slot="imageUrl" slot-scope="imageUrl, record" :src="record.imageUrl" alt="海报"/>
        <span slot="state" slot-scope="state, record">{{stateMap.get(record.state)}}</span>
        <span slot="action" slot-scope="id,record">
          <router-link v-if="operation.edit" :to="`/appletsManage/sharePoster/add/${record.id}`">编辑</router-link>
          <a-popconfirm
            v-if="operation.down"
            style="margin-left: 10px"
            :title="`是否确定${record.state === 2 ? '下架' : '上架'}该海报`"
            @confirm="handleDown(record)"
          >
            <a>{{record.state === 2 ? '下架' : '上架'}}</a>
          </a-popconfirm>
        </span>
      </a-table>
      <MyPagination
        :count="total"
        :pageNo="pageNo"
        @showSizeChangeFn="paginationCallBack"
        v-show="!tableLoading"
      />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { codeFn } from '@/utils/tools'
import MyPagination from '@/components/pagination/MyPagination'
import { sharePosterColumns } from '../constants'
import { getShopPlaybillList, shopPlaybillRemoved } from '@/request/api/indexManage'
const stateMap = new Map([
  [3, '已下架'],
  [2, '已上架'],
])
export default {
  components: { MyPagination },
  data() {
    return {
      stateMap,
      operation: {
        add: false,
        edit: false,
        down: false
      },
      tableLoading: false,
      sharePosterColumns,
      tabledData: [],
      total: 0,
      pageNo: 1,
      pageSize: 20,
      filter: {
        state: 0,
        name: ''
      }
    };
  },
  computed: {
    isShow() {
      return this.$route.meta.isShow
    }
  },
  watch: {
    isShow(val) {
      if (val) this.fetchData()
    }
  },
  created() {
    this.operation.add = codeFn('addSharePoster')
    this.operation.edit = codeFn('editSharePoster')
    this.operation.down = codeFn('downSharePoster')
    this.fetchData()
  },
  methods: {
    async handleDown(record) {
      console.log(record)
      const state = record.state === 2 ? 3 : 2
      const params = {
        state: state,
        id: record.id
      }
      const { code } = await shopPlaybillRemoved(params)
      if (code !== 200) return
      const title = record.state === 2 ? '下架' : '上架'
      this.$message.success(`${title}成功`)
      this.fetchData()
    },
    onSearch() {
      this.pageNo = 1
      this.fetchData()
    },
    // 获取列表
    async fetchData() {
      this.tableLoading = true
      const params = {
        source: 2, // 1日签，2邀请
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.filter
      }
      const { data, code } = await getShopPlaybillList(params)
       this.tableLoading = false
      if (code !== 200) return
      this.total = data.count
      this.tabledData = data.data
    },
    // 分页功能切换的回调
    paginationCallBack(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize
      this.fetchData()
    }
  }
};
</script>
 
<style lang="less" scoped>
.header-box {
  display: flex;
  margin-bottom: 30px;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.btn-edit {
  margin-right: 10px;
}
.table-operator{
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}
</style>