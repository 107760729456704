// 签到海报字段
export const sharePosterColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    key: 'number',
    scopedSlots: {customRender: 'number'},
  },
  {
    title: '海报图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    scopedSlots: {customRender: 'imageUrl'}
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: {customRender: 'state'}
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '180px',
    scopedSlots: {customRender: 'action'}
  },
]